/**
 * Copyright Compunetix Incorporated 2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IParameter, AlertLevel, ParameterType } from "companion";
import { AlertService } from "../alert/alert.service";
import { FormService } from "../form/form.service";
import { GroupManagementService, IPurgeRequest } from "./group-management.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { format, subYears } from "date-fns";

@Component({
  selector: "purge-report-form",
  templateUrl: "./purge-report-form.component.html"
})

/**
 * purge report form
 */
export class PurgeReportFormComponent {
  /**
   * group id
   */
  groupId: string;

  /**
   * dialog id
   */
  dialogId: string;

  /**
   * current parameters
   */
  currentParameters: { [key: string]: IParameter };

  /**
   * current form
   */
  currentForm: FormGroup;

  /**
   * send invitation
   */
  purgeReportData() {
    this.alertService.createAlert("DELETING_OLD_DATA", "Deleting Old Data...", AlertLevel.success);
    FormService.updateParametersByForm(this.currentForm, this.currentParameters);
    this.groupManamentService
    .purgeReportData(this.convertParametersToRequest(this.currentParameters))
    .then(() => {
      this.alertService.createAlert("DELETE_OLD_DATA_SUCCESS", "Delete Old Data Successfully!", AlertLevel.success);
      Dispatcher.dispatch(ActionType.HideModalDialog, this.dialogId);
    })
    .catch((err: Error) => {
      this.alertService.createAlert("DELETE_OLD_DATA_FAIL", err.message, AlertLevel.warning);
    });
  }

  cancel() {
    Dispatcher.dispatch(ActionType.HideModalDialog, this.dialogId);
  }

  constructor(private groupManamentService: GroupManagementService, private alertService: AlertService) {
    Dispatcher.register(ActionType.OpenPurgeDataDialog, (payload: any) => {
      this.groupId = payload.groupId;
      this.dialogId = payload.dialogId;
      this.formInit();
      Dispatcher.dispatch(ActionType.OpenModalDialog, payload.dialogId);
    });
  }

  formInit() {
    this.currentParameters = this.getDefaultFormParameters();
    this.currentForm = FormService.toFormGroup(this.currentParameters);
  }

  /**
   * get default request form template parameters
   */
  getDefaultFormParameters(): { [key: string]: IParameter } {
    let formParameters: { [key: string]: IParameter } = {};
    formParameters[PurgeFormKey[PurgeFormKey.purge_group_id]] = {
      key: PurgeFormKey[PurgeFormKey.purge_group_id],
      title: "Group Id",
      type: ParameterType[ParameterType.hidden],
      value: this.groupId
    };
    formParameters[PurgeFormKey[PurgeFormKey.purge_date]] = {
      key: PurgeFormKey[PurgeFormKey.purge_date],
      title: "Delete Any Data Older Than:",
      type: ParameterType[ParameterType.date],
      value: format(subYears(new Date(), 1), "yyyy-MM-dd")
    };
    return formParameters;
  }

  convertParametersToRequest(parameters: { [key: string]: IParameter }): IPurgeRequest {
    let request: IPurgeRequest = {};
    let parameterValues: { [key: string]: any } = FormService.collectParameterValues(parameters);
    request.groupId = parameterValues[PurgeFormKey[PurgeFormKey.purge_group_id]];
    request.date = new Date(parameterValues[PurgeFormKey[PurgeFormKey.purge_date]]);
    return request;
  }
}

enum PurgeFormKey {
  purge_group_id,
  purge_date
}
