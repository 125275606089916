/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, ViewChild, OnInit } from "@angular/core";
import { GroupManagementService } from "./group-management.service";
import { IGroup, Companion, Alert, AlertLevel, Cookie } from "companion";
import { GroupDetailsComponent } from "./group-details.component";

import { AlertService } from "../alert/alert.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { NavBarMenuItemKey } from "../layout/nav-bar/nav-bar.service";

@Component({
  selector: "group-management",
  templateUrl: "./group-management.template.html"
})
export class GroupManagementComponent implements OnInit {
  // the group array
  rows: any[] = [];
  selected: any = [];
  selectedGroup: IGroup;
  purgeDataDialogId: string = "purge-report-data-modal";
  // flag if user can manage vcc features and licenses
  canManageVCC: boolean;
  // flag if user can delete report data
  canDeleteReportData: boolean;

  @ViewChild(GroupDetailsComponent)
  GroupDetailsComponent: GroupDetailsComponent;

  @ViewChild(DatatableComponent) groupTable: DatatableComponent;

  /**
   * # entries per table page.
   */
  limit: number = 10;

  /**
   * Group profile service
   */
  private groupManagementService: GroupManagementService;

  /**
   * hold the filter value
   */
  private filterValue: string;

  constructor(groupManagementService: GroupManagementService, private alertService: AlertService) {
    this.groupManagementService = groupManagementService;
    Dispatcher.register(ActionType.LoadGroupDataCallback, () => {
       this.updateGroupsListPerFilter();
    });
  }

  ngOnInit() {
    if (
      !Companion.getUserService().currentUser.isAuthenticated ||
      !(Companion.getUserService().currentUser.permissions.createChildGroups > 0)
    ) {
      Dispatcher.dispatch(ActionType.OpenDefaultScreen);
      return;
    }
    Dispatcher.dispatch(ActionType.LoadHostUserNavBar, { active: NavBarMenuItemKey.GroupManagement });
    this.getGroups();
    this.canManageVCC = Companion.getUserService().currentUser.permissions.canManageVCC > 0;
    this.canDeleteReportData = Companion.getUserService().currentUser.permissions.deleteBillingData > 0;
    if(Cookie.getCookie("groupLimit"))
    {
      this.limit = Number(Cookie.getCookie("groupLimit"));
    }
  }

  getGroups() {
    Dispatcher.dispatch(ActionType.LoadGroupData);
  }

  updateRows(groups: IGroup[]) {
    this.rows = groups;
  }

  onSelect($event: Event) {
    this.selectedGroup = this.selected[0];
  }

  /**
   * Create group form
   */
  createGroup() {
    Dispatcher.dispatch(ActionType.OpenGroupForm, { isEditing: false });
  }

  /**
   * Delete a group
   */
  deleteGroup() {
    if (this.selectedGroup) {
      // confirm that we want to delete the group
      AlertService.createAlertWithButtons("Are you sure you want to delete " + this.selectedGroup.name + "?", {
        confirm: {
          label: "Yes",
          className: "btn-success"
        },
        cancel: {
          label: "No",
          className: "btn-default"
        }
      }).then((result: boolean) => {
        if (result) {
          return this.groupManagementService
          .deleteGroup(this.selectedGroup)
          .then((success: boolean) => {
            // update the groups after a delete
            this.getGroups();
          })
          .catch((error: Error) => {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert(
                error.message,
                "Cannot delete this group because it has user or subgroup related.",
                AlertLevel.warning
              )
            });
          });
        }
      })
      .catch((error) => {
        console.log("Failed to deleteGroup: ", error);
      });
    }
  }

  /**
   * edit a group
   */
  editGroup() {
    // get the selected group
    if (this.selectedGroup) {
      if (!this.groupManagementService.hasPermissionToEdit(this.selectedGroup["_id"])) {
        Dispatcher.dispatch(ActionType.Alert, {
          alert: new Alert(
            "NO_PERMISSION_TO_CHANGE_GROUP",
            "You do not have permission to change this group",
            AlertLevel.warning
          )
        });
        return;
      }
      Dispatcher.dispatch(ActionType.OpenGroupForm, { isEditing: true, group: this.selectedGroup });
    }
  }

  /**
   * filter table by name
   */
  updateFilter(event) {
    this.filterValue = event.target.value.toLowerCase();
    this.updateGroupsListPerFilter();
    // Whenever the filter changes, always go back to the first page
    this.groupTable.offset = 0;
  }

  setLimit(limit : number)
  {
    this.limit = limit;
    // update COOKIE
    Cookie.setCookie("groupLimit", limit.toString());
  }

  /**
   * purge old report data
   */
  promptPurgeReportData(groupId: string) {
    Dispatcher.dispatch(ActionType.OpenPurgeDataDialog, { groupId: groupId, dialogId: this.purgeDataDialogId });
  }

  hasPermissionToDelete(groupId: string): boolean {
    let result: boolean = true;
    let group: IGroup = this.groupManagementService.allAccessibleGroupsDictionary[groupId];
    if (!group) {
      return false;
    }
    if (!this.groupManagementService.hasPermissionToDeleteReportData(group["_id"])) {
      return false;
    }
    return result;
  }

   updateGroupsListPerFilter()
   {
      // filter our data
      const results = _.filter(_.values(this.groupManagementService.allAccessibleGroupsDictionary), (group: IGroup) => {
         return group.name.toLowerCase().indexOf(this.filterValue) > -1 || !this.filterValue;
      });
      // update rows
      this.updateRows(results);
   }

}
