<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<top-bar></top-bar>
<nav-bar></nav-bar>
<main-container>
  <div class="group-management-page p-2">
    <h3>Groups</h3>
    <div class="row mb-1">
      <div class="col-md-4">
        <button
          type="button"
          (click)="createGroup()"
          class="btn btn-primary text-uppercase"
        >Create Group</button>
        <button
          type="button"
          (click)="editGroup()"
          class="btn btn-primary text-uppercase"
        >Edit Group</button>
        <button
          type="button"
          (click)="deleteGroup()"
          class="btn btn-primary text-uppercase"
        >Delete Group</button>
      </div>
      <div class="col-md-4" style="font-weight: 500">
        Per Page: 
        <button type="button" (click)="setLimit(10)" 
          [ngClass]="{'btn-primary': limit === 10, 'btn-secondary': limit !== 10}" class="btn">
          10
        </button>
        <button type="button" (click)="setLimit(25)"
          [ngClass]="{'btn-primary': limit === 25, 'btn-secondary': limit !== 25}" class="btn">
          25
        </button>
        <button type="button" (click)="setLimit(50)"
          [ngClass]="{'btn-primary': limit === 50, 'btn-secondary': limit !== 50}" class="btn">
          50
        </button>
    </div>
      <div class="col-md-4">
        <input
          type="text"
          class="form-control"
          placeholder="Type to filter the name column..."
          (keyup)="updateFilter($event)"
        />
      </div>
    </div>
    <ngx-datatable
      #groupTable
      class='material striped expandable'
      [rows]='rows'
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="50"
      [scrollbarH]="true"
      [sortType]="'multi'"
      [selected]="selected"
      [selectionType]="'single'"
      [limit]="limit"
      (select)='onSelect($event)'
    >
      <!-- Column Templates -->
      <ngx-datatable-column
        name="Group Name"
        prop="name"
        headerClass="table-column-header"
      ></ngx-datatable-column>
      <ng-container *ngIf="canManageVCC">
        <ngx-datatable-column
          name="Online Op/Sp Count"
          prop="onlineOpCount"
          headerClass="table-column-header"
        ></ngx-datatable-column>
        <ngx-datatable-column
          name="Max Op/Sp Allowed"
          prop="maxOpCount"
          headerClass="table-column-header"
        ></ngx-datatable-column>
      </ng-container>
      <ng-container *ngIf="canDeleteReportData">
        <ngx-datatable-column
          name="Actions"
          sortable="false"
          prop="_id"
        >
          <ng-template
            let-row="row"
            let-groupId="value"
            ngx-datatable-cell-template
          >
            <button
              class="btn-sm btn-danger"
              *ngIf="hasPermissionToDelete(groupId)"
              (click)="promptPurgeReportData(groupId)"
            >
              <i class="fas fa-eraser"></i> Delete Old Data
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>
    </ngx-datatable>
  </div>
  <dialog-container-modal [id]="'group-form'">
    <group-form></group-form>
  </dialog-container-modal>

  <dialog-container-modal [id]="purgeDataDialogId">
    <purge-report-form>
    </purge-report-form>
  </dialog-container-modal>
</main-container>
<footer></footer>